* {
  box-sizing: border-box;
}

a {
  &:focus,
  &:active {
    outline: none
  }
  color: #4a4a4a;
  text-decoration: none;
}

body {
  color: #4A4A4A;
  font-family: 'Open Sans', sans-serif;
  background: transparent;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.Toastify__toast-container{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.Toastify__toast--default {
  background-color: #FFFFFF !important;
  border-radius: 14.4px 14.4px 14.4px 0 !important;
  min-height: 50px !important;
  min-width: 307px !important;
  margin: 5px;
  padding: 0 15px;
  color: #3A3A3A;
  font-size: 11px;
  line-height: 10px;
  display: flex;
  align-items: center;
}
